<template>
  <div>
    <div id="mapDetail"></div>
    <div class="detailPosition" v-show="showDetail">
      <marker-detail :detail="detail" @close="close" @toDetail="toDetail" @toGo="toGo"
                     @toPhone="toPhone"/>
    </div>
    <el-dialog :show-close="false" :visible.sync="showPopup" center title="使用地图打开">
      <open-app :keyword="detail.orgAddr" :latitude="detail.lat" :longitude="detail.lng"></open-app>
    </el-dialog>
  </div>
</template>

<script>
import Api from '../../api'
import markerDetail from "../../components/marker-detail/marker-detail"
import openApp from "../../components/open-map-app/index.vue"

const api = new Api()

export default {
  components: {
    openApp,
    markerDetail
  },
  data() {
    return {
      showDetail: true,
      // 大板云工厂
      big_intelligent: require('../../assets/big_intelligent.png'),
      // 成品云工厂
      big_machining: require('../../assets/big_machining.png'),
      // 材料商
      big_buisness: require('../../assets/big_buisness.png'),
      showPopup: false,
      scale: 12,
      latitude: this.$route.query.latitude,
      longitude: this.$route.query.longitude,
      type: this.$route.query.orgType,
      id: this.$route.query.orgId,
      markers: [],
      detail: {},
    }
  },
  methods: {
    close() {
      this.showDetail = false
    },
    initMap() {
      const cssM = {
        background: "#9931F1",
        width: '80px',
        maxWidth: '80px',
        whiteSpace: 'normal',
        border: "none",
        borderRadius: "5px",
      };
      const cssI = {
        background: "#F15131",
        width: '80px',
        maxWidth: '80px',
        border: "none",
        whiteSpace: 'normal',
        borderRadius: "5px"
      };
      const cssB = {
        background: "#02AF66",
        width: '80px',
        maxWidth: '80px',
        whiteSpace: 'normal',
        border: "none",
        borderRadius: "5px"
      };
      const orgName = this.detail.orgName
      const type = Number(this.type)
      let orgStyle = null, image = ''
      orgStyle = type === 1 ? cssM : type === 2 ? cssI : cssB
      image = type === 1 ? this.big_machining : type === 2 ? this.big_intelligent : this.big_buisness
      const _this = this
      const center = new window.qq.maps.LatLng(Number(_this.latitude), Number(_this.longitude))
      _this.map = new window.qq.maps.Map(document.getElementById('mapDetail'), {
        center: center,
        zoom: Number(this.scale),
        minZoom: 4,
        maxZoom: 20,
        disableDefaultUI: true,
        scrollwheel: true
      })
      // 创建一个Marker
      // let marker = new qq.maps.Marker({
      //   //设置Marker的位置坐标
      //   position: new window.qq.maps.LatLng(_this.latitude, _this.longitude),
      //   //设置显示Marker的地图
      //   map: _this.map
      // });
      //初始化label
      let label = new window.qq.maps.Label({
        map: _this.map,
        position: new window.qq.maps.LatLng(_this.latitude, _this.longitude),
        zIndex: 10, // 信息窗的z-index值
        offset: new qq.maps.Size(-42, -20),
        style: orgStyle,
        enableCustom: true,
        content: type === 1 ? `<div class="marker">${orgName}<span class="span1"></span></div>` : type === 2 ? `<div class="marker">${orgName}<span class="span2"></span></div>` : `<div class="marker">${orgName}<span class="span3"></span></div>`
      });
      //设置Marker的动画属性为从落下
      // marker.setAnimation(qq.maps.MarkerAnimation.DOWN);
      // //设置Marker是否可以被拖拽，为true时可拖拽，false时不可拖拽，默认属性为false
      // marker.setDraggable(false);
      // //设置Marker自定义图标的属性，size是图标尺寸，该尺寸为显示图标的实际尺寸，origin是切图坐标，该坐标是相对于图片左上角默认为（0,0）的相对像素坐标，anchor是锚点坐标，描述经纬度点对应图标中的位置
      // const size = new qq.maps.Size(57, 66),
      //     icon = new qq.maps.MarkerImage(
      //         image,
      //         size
      //     );
      // marker.setIcon(icon);
      // 标记Marker点击事件
      window.qq.maps.event.addListener(label, 'click', function () {
        _this.showDetail = true
      });
    },
    getDetail(id) {
      const param = {
        orgId: id
      }
      api.getOrgInfo(param).then(res => {
        this.detail = res.data
        this.detail.orgType = this.type
        this.initMap()
      })
    },
    // 导航
    toGo() {
      this.showPopup = !this.showPopup
    },
    // 打电话
    toPhone() {
      window.location.href = 'tel://' + this.detail.contactPhone
    },
    // 跳转详情
    toDetail(item) {
      if (item.orgTypes.indexOf("3") != -1) {
        // 跳转到大板市场店铺页
        window.location.href = process.env.VUE_APP_SHOP_SERVER + '/Content/Vue/new-shop/my-shop.html?officeCode=' + item.orgId + '&isShare=2'
      } else {
        // 跳转到产业地图店铺页
        this.$router.push({
          path: "/detail",
          query: {
            orgId: item.orgId
          }
        });
      }
    }
  },
  mounted() {
    this.getDetail(this.id)
  }
}
</script>
<style>
.detailPosition {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: #FFFFFF;
  border-radius: 16px 16px 0px 0px;
  /*padding: 15px 15px 30px 15px;*/
}

.marker {
  position: relative;
  color: #ffffff;
  text-align: center;
  padding: 1px 2px;
  font-weight: normal;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  /*overflow: hidden;*/
  -webkit-box-orient: vertical;
}

.marker span {
  position: absolute;
  border-left: solid 7px transparent;
  border-right: solid 7px transparent;
  /*border-bottom: solid 11px transparent;*/
  top: 100%;
  left: 50%;
  margin-left: -7px;
  /*top: 50%;*/
  /*left: 0;*/
  /*margin-top: -11px;*/
  /*margin-left: -22px;*/
  width: 0;
  height: 0;
}

.span1 {
  border-top: solid 8px #9931F1;
}

.span2 {
  border-top: solid 8px #F15131;
}

.span3 {
  border-top: solid 8px #02AF66;
}

.el-dialog__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.el-dialog {
  margin-top: 0 !important;
  border-radius: 10px !important;
  width: 80% !important;
}

.el-dialog__title {
  font-size: 18px;
  line-height: 18px !important;
  color: #333 !important;
  margin-bottom: 10px;
  font-weight: 600;
}

.el-dialog__body {
  padding-top: 0 !important;
}

.el-message-box {
  width: 300px !important;
}
</style>
<style lang="less" scoped>

#mapDetail {
  /*地图(容器)显示大小*/ /*设置为全屏，位于最下层*/
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
}
</style>
